












































































































































































import Vue from "vue";
import _ from 'lodash';
import { ulid } from 'ulid';
import notificationMixin from '../../mixin/notification';
import store from '../../store/shop';
import ShopAPI from '../../common/api/api_shop';
import { SessionStatus } from "../../model/entity/tableSessionSetting";
import { TranslateServiceBuilder } from '../../services/Translate/translateServiceBuilder';

const translate = TranslateServiceBuilder.Instance()._;
const api = new ShopAPI();

Vue.directive('enhance-defaulot-value', {
    bind: function (el) {
        el.addEventListener('focus', function() {
            const value = el.value;
            if (value == 0) {
                el.value = '';
            }
        });
        el.addEventListener('blur', function() {
            const value = el.value;
            if (value == '') {
                el.value = 0;
            }
        });
    }
});

export default Vue.component('Closing', {
  components: {
    QLoader: () => import('../../components/elements/QLoader.vue'),
    QButton: () => import("../../components/elements/QButton.vue"),
    QTranslate: () => import("../../components/elements/QTranslate.vue"),
    QError: () => import("../../components/elements/QError.vue"),
  },
  mixins: [
    notificationMixin,
  ],
  data() {
    return {
        isEdit: false,
        isCorrectError: false,
        isWithin: false,

        newItem: {},
        currentItem: {},
        denomilist: [],
        correctlist: [],
        newCorrect: {},
        currentCorrect: {},
        editIndex: "",

        errors: {
            journal_corrects: {},
            journal_denomis: {},
        },
        errorsCorrects: {
            comment: [],
            total_price: [],
        },
        salesAll: 0,
        salesOther: 0,
        discountAll: 0,

        mode: '',
        isLoading: false,
    };
  },
  mounted() {
    api.set_token(store.state.token);
    this.fetchJournaldata();
  },
  computed: {
    setting() {
      return store.state.shopSetting;
    },
    prefix() {
      return this.$route.params.prefix;
    },
    shoppix() {
      return this.$route.params.shoppix;
    },
    staffId(): string {
      return store.state.staff.id;
    },
    reserveMoney()  {
        return this.newItem.reserve_money;
    },
    cash() {
        return this.denomilist.reduce(function(sum, item) {
            return sum + (item.unit_price * (item.amount || 0));
        }, 0)
    },
    correctAll() {
        return this.correctlist.reduce(function(sum, item) {
            return sum + item.total_price;
        }, 0)
    },
    difference() {
        let price = this.cash + this.salesOther - this.newItem.reserve_money - this.salesAll - this.correctAll + this.discountAll;
        if (Number.isFinite(price)) {
            return price;
        }
        return 0;
    },
},
  methods: {
    handleClickEdit: function(index) {
        if (this.isLoading) {
            return;
        }
        this.isEdit = true;
        this.editIndex = index;
        this.setCorrect(this.correctlist[index]);
    },
    handleClickAdd: function() {
        if (this.isLoading) {
            return;
        }
        this.mode = '';

        this.setCorrect(this.newCorrect);
    },
    handleClickAddList: function () {
        this.checkCorrect();
        if (!this.isCorrectError) {
            this.addCorrect();
        }
    },
    handleClickSubmit: function () {
        if (this.isLoading) {
            return;
        }
        this.isLoading = true;

        this.newItem.id = ulid();
        this.newItem.journal_denomis = this.denomilist;
        this.newItem.journal_corrects = this.correctlist;
        this.newItem.staff_id = this.staffId;
        if (this.newItem.reserve_money == "") {
            this.newItem.reserve_money = 0;
        }
        this.setItem(this.newItem);
        this.submitItem();
    },
    handleClickCloseEditor: function () {
        this.closeEditor();
    },
    handleClickDelete: function () {
        this.deleteCorrect();
    },

    setItem: function(item) {
        this.currentItem = _.cloneDeep(item)
    },
    setCorrect: function(correct) {
        this.currentCorrect = _.cloneDeep(correct);
        this.mode = "edit";
    },
    addCorrect: function () {
        if (this.isEdit) {
            this.correctlist.splice(this.editIndex, 1, this.currentCorrect);
        } else {
            this.correctlist.push(this.currentCorrect);
        }
        this.closeEditor();
    },
    deleteCorrect: function () {
        this.correctlist.splice(this.editIndex, 1);
        this.closeEditor();
    },
    checkCorrect: function () {
        this.errorsCorrects = {
            comment: [],
            total_price: [],
        }
        this.isCorrectError = false;
        if (!this.currentCorrect.comment) {
            this.errorsCorrects.comment.push(translate('hall-0077', '補正理由 が入力されていません'));
            this.isCorrectError = true;
        } else if (this.currentCorrect.comment.length > 17) {
            this.errorsCorrects.comment.push(translate('hall-0078', '補正理由 の最大文字数は 17 文字です'));
            this.isCorrectError = true;
        }
        if (!this.currentCorrect.total_price && this.currentCorrect.total_price !== 0) {
            this.errorsCorrects.total_price.push(translate('hall-0079', '金額 が入力されていません'));
            this.isCorrectError = true;
        }
    },
    closeEditor: function() {
        this.mode = '';
        this.isEdit = false;
        this.isLoading = false;
        this.editIndex = "";
        this.cleanup();
    },
    cleanup: function (target = null) {
        if (target && this.errors.target) {
            return this.errors.target = null;
        }
        if (target && this.errorsCorrects.target) {
            return this.errorsCorrects.target = null;
        }
        this.errors = {
            journal_corrects: {},
            journal_denomis: {},
        }
        this.errorsCorrects = {
            comment: [],
            total_price: [],
        }
    },

    submitItem: function () {
      let message = '';
      api
        .journals_edit(
            { journal: this.currentItem },
            { journal_id: this.currentItem.id},
        )
        .then((response) => {
          if (response.data.status !== 'success') {
            message = response.data.message || translate('hall-0046', '保存に失敗しました。');
            this.showErrorNotification(message);
            this.errors = response.data.errors;
            this.isLoading = false;
            return;
          }
          message = response.data.message;
          this.showSuccessNotification(message);
          this.closeEditor();
          this.fetchJournaldata();
          this.mode = 'finish';
        })
        .catch((err) => {
            message = err.response.data.message || translate('hall-0046', '保存に失敗しました。');
            this.showErrorNotification(message);
            this.errors = err.response.data.errors;
            this.isLoading = false;
        })
    },
    fetchJournaldata() {
      this.isLoading = true;
      let message = '';
      api
        .journals_closing()
        .then(response => {
          if (response.data.status != "success") {
            message = response.data.message || translate('hall-0048', 'データの取得に失敗しました。');
            this.showErrorNotification(message);
            this.errors = message;
            return;
          }
            this.isLoading = false;
            this.newItem = response.data.data.journal;
            this.isWithin = response.data.data.isWithin;
            this.denomilist = this.newItem.journal_denomis;
            this.correctlist = this.newItem.journal_corrects;
            this.salesAll = response.data.data.sales.all;
            this.salesOther = response.data.data.sales.other;
            this.discountAll = response.data.data.sales.discount;
            if (this.isWithin && (this.mode !== 'finish')) {
                this.mode = "confirm";
            }
        })
        .catch((err) => {
            this.isLoading = false;
            message = err.response.data.message || translate('hall-0048', 'データの取得に失敗しました。');
            this.showErrorNotification(message);
            this.errors = message;
        })
    },
  },
  filters: {
    numberFormat: function (number) {
        if (typeof number !== "number") {
            return number;
        }
        return number.toLocaleString();
    },
    setErrorForm(value) {
        return (value) ? "form-error" : "";
    },
    setDisabled: function (value) {
        if (value != 0) {
            return "disabled";
        }
    },
    setErrorCorrects: function (value) {
        return (value && value.length > 0) ? "form-error" : "";
    },
    isErrorDenomis: function (err, value) {
        return (err && Object.keys(err).length > 0) || value < 0 ? "form-error" : "";
    },
  },
});
